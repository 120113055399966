import React, { useState, useContext } from "react";
import { useParams } from "react-router-dom";
import productData from "../Datas/productData";
import Header from "./Header";
import Footer from "./Footer";
import "../css/productdetails.css";
import { useNavigate } from "react-router-dom";
import { CartContext } from "../Component/CartContext";

const ProductDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const product = productData.find((item) => item.id === parseInt(id));
  const { addToCart } = useContext(CartContext);
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleAddToCart = () => {
    addToCart(product.id, 1);
    navigate("/cartpage");
  };
  if (!product) {
    return <div>Product not found</div>;
  }

  return (
    <div className="main-container">
      <Header />
      <div className="shopDiv">
        <div className="product-detail">
          <div className="product-images">
            <div className="carousel">
              <div>
                {product.images.map((image, index) => (
                  <img
                    key={index}
                    src={image}
                    alt={`${product.name}`}
                    style={{
                      display: index === currentIndex ? "block" : "none",
                    }}
                  />
                ))}
                <div className="carousel-buttons">
                  {product.images.map((image, index) => (
                    <button key={index} onClick={() => setCurrentIndex(index)}>
                      <img src={image} alt={`${product.name}`} />
                    </button>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="product-info">
            <div className="g-0">
              <div className="bg-quaternary">
                <h3>{product.name}</h3>
                <h4>£{product.price}</h4>
                <p>{product.details}</p>
                <div className="btnDiv">
                  <button className="add-to-cart" onClick={handleAddToCart}>
                    Add to Cart
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="des--div">
          <ul className="item--ul">
            {product.description.map((des, index) => (
              <li key={index}>
                <div className="woocommerce-product-details__short-description">
                  <p>{des}</p>
                </div>
              </li>
            ))}
          </ul>
        </div>
        <div className="line-term">
          <div className="line-term-inner">Terms & Conditions</div>
        </div>
        <div className="line-term">
          <div className="line-term-inner1">
            <p>Copyright © 2024, Premier Foods Books. Powered by Shopify</p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ProductDetail;
