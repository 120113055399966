const productData = [
  {
    id: 1,
    name: "Be-Ro Recipe Book",
    price: "5.99",
    details:
      "Postage and Packaging costs will be added at the checkout.Tax included.",
    description: [
      "First published in 1923, and now in its 41st edition, the 'Be-Ro' recipe book is arguably one of the best-selling cookery books ever, with more than thirty eight million copies having been sold.",
      "The latest edition is packed with even more inspiring dishes together with a brand new 'quick and easy' section which even the novice baker can follow.",
    ],
    images: ["/bero/1001.png", "/bero/1001a.png"],
  },
];

export default productData;
