import "../css/header.css";
import React, { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { CartContext } from "../Component/CartContext";

function Header({
  onNavigate,
  onNavigateToBook,
  onNavigateToFAQs,
  onNavigateToContact,
}) {
  const { cartItems } = useContext(CartContext);
  const navigate = useNavigate();

  const toTop = () => {
    window.scrollTo({
      top: 100,
      behavior: "smooth",
    });
  };

  const toHome = () => {
    navigate("/");
  };

  return (
    <div className="header-container">
      <div className="headerDiv">
        <div className="leftImg">
          <img
            alt=""
            src="/head/head1.png"
            className="head1-png"
            onClick={toHome}
          />
        </div>
        <div className="headerMid">
          <nav className="navigation">
            <ul className=" main-nav">
              <li className="menu-item">
                <Link to="/" className="homeToA" onClick={onNavigate}>
                  Our Story
                </Link>
              </li>
              <li className="menu-item">
                <Link to="/" className="homeToA" onClick={onNavigateToBook}>
                  Recipe Book
                </Link>
              </li>
              <li className="menu-item">
                <Link to="/" onClick={onNavigateToFAQs}>
                  FAQs
                </Link>
              </li>
              <li className="menu-item">
                <Link to="/" onClick={onNavigateToContact}>
                  Contact Us
                </Link>
              </li>
            </ul>
          </nav>
        </div>
        <div className="rightT">
          <p className="cartItemCount">{cartItems.length}</p>
          <Link to="/cartpage" className="to-add" onClick={toTop}>
            <img
              width="30"
              height="30"
              src="https://img.icons8.com/metro/26/FFFFFF/shopping-cart.png"
              alt="shopping-cart"
            />
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Header;
