import React, { useRef, useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import "../css/main.css";
import "../css/shop.css";
import { Link } from "react-router-dom";

function Main() {
  const [file, setFile] = useState("");
  const [firstname, setFirstname] = useState("");
  const [contact, setContact] = useState("");
  const [site, setSite] = useState("");
  const [reason, setReason] = useState("");
  const [brand, setBrand] = useState("");
  const [title, setTitle] = useState("");
  const [lastname, setLastname] = useState("");
  const [add1, setAdd1] = useState("");
  const [add2, setAdd2] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [code, setCode] = useState("");
  const [country, setCountry] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [emailAdd, setEmailadd] = useState("");
  const [msg, setMsg] = useState("");
  const [error, setErrors] = useState("");
  const [activeIndex, setActiveIndex] = useState(null);
  const storyRef = useRef(null);
  const bookRef = useRef(null);
  const faqsRef = useRef(null);
  const contactRef = useRef(null);

  const handleNavigateToStory = () => {
    if (storyRef.current) {
      window.scrollTo({
        top: storyRef.current.offsetTop - 80,
        behavior: "smooth",
      });
    }
  };

  const handleNavigateToBook = () => {
    if (bookRef.current) {
      window.scrollTo({
        top: bookRef.current.offsetTop - 80,
        behavior: "smooth",
      });
    }
  };

  const handleNavigateToFAQs = () => {
    if (faqsRef.current) {
      window.scrollTo({
        top: faqsRef.current.offsetTop - 80,
        behavior: "smooth",
      });
    }
  };

  const handleNavigateToContact = () => {
    if (contactRef.current) {
      window.scrollTo({
        top: contactRef.current.offsetTop - 80,
        behavior: "smooth",
      });
    }
  };

  const handleToggle = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const handleNavigate = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleNavigateToPdf = (e) => {
    e.preventDefault();
    window.open("/bero/Be-Ro_website_recipes.pdf", "_blank");
  };

  function handleFileChange(event) {
    setFile(event.target.files[0]);
  }

  function handleFileSubmit(event) {
    event.preventDefault();

    if (file) {
      const formData = new FormData();
      formData.append("fileUpload", file);

      // 使用fetch API发送文件到服务器
      fetch("/upload", {
        method: "POST",
        body: formData,
      })
        .then((response) => response.json())
        .then((data) => console.log("Success:", data))
        .catch((error) => console.error("Error:", error));
    }
  }

  const handlePlaceOrder = () => {
    const newErrors = {};
    if (!contact) newErrors.contact = "contact is required";
    if (!email) newErrors.email = "Email is required";
    if (!site) newErrors.site = "site is required";
    if (!reason) newErrors.reason = "reason is required";
    if (!brand) newErrors.brand = "brand is required";
    if (!lastname) newErrors.lastname = "lastname is required";
    if (!code) newErrors.code = "code is required";
    if (!country) newErrors.country = "country is required";
    if (!emailAdd) newErrors.emailAdd = "emailAdd is required";
    if (!msg) newErrors.msg = "msg is required";

    setErrors(newErrors);

    if (Object.keys(newErrors).length > 0) {
      alert("Please fill all require");
    } else {
      alert("send Success!");
    }
  };

  return (
    <div className="main-container">
      <Header
        onNavigate={handleNavigateToStory}
        onNavigateToBook={handleNavigateToBook}
        onNavigateToFAQs={handleNavigateToFAQs}
        onNavigateToContact={handleNavigateToContact}
      />
      <div className="mainBox">
        <div className="twoDiv">
          <div className="jDxKwq">
            <img
              src="/head/1001.jpg"
              alt="Our Story"
              style={{ transform: "none", position: "static" }}
            />
            <div className="two-img">
              <img src="/head/1002.png" alt="Our Story" />
            </div>
          </div>
        </div>

        <div className="mainBox1">
          <div className="container-fluid">
            <h1 ref={storyRef}>Our Story</h1>
            <table>
              <tbody>
                <tr>
                  <td style={{ width: "80%" }}>
                    Thomas Bell founded a wholesale grocery firm near the Tyne
                    quays and railway station in Newcastle in the 1880s. Among
                    his top-selling brands were 'Bells Royal' baking powder and
                    a self raising flour. Following the death of Edward VII, it
                    became illegal to use the Royal name. As a result, Bell
                    decided to take the first couple of letters from the each of
                    the two words of the brand name and turn them into the more
                    catchy sounding '<b>Be-Ro</b>'.
                  </td>
                  <td style={{ width: "20%" }}>
                    <span style={{ color: "#000000" }}>
                      <img
                        alt=""
                        align="right"
                        border="0"
                        height="220"
                        src="/head/1003.gif"
                        width="158"
                      />
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>

            <table>
              <tbody>
                <tr>
                  <td style={{ width: "20%" }}>
                    <span>
                      <img
                        alt=""
                        align="left"
                        border="0"
                        height="222"
                        src="/head/1004.gif"
                        width="158"
                      />
                    </span>
                  </td>
                  <td style={{ width: "80%" }}>
                    <b>Raising expectations</b>
                    <br />
                    <br />
                    In the early 1920s, the most commonly used type of flour was
                    plain flour. Self-raising flour was more expensive and
                    considered a novelty - consumers bought plain flour direct
                    from the miller and self-raising flour was only sold into
                    independent grocers.
                    <br />
                    <br />
                    In a bid to make self-raising flour more popular among the
                    general public, the company staged a series of exhibitions
                    in the early 1920s where freshly baked scones, pastries and
                    cakes were sold for a shilling to visitors. These were so
                    popular that people demanded to have copies of the recipes
                    so that they could bake the dishes at home.
                  </td>
                </tr>
              </tbody>
            </table>
            <table>
              <tbody>
                <tr>
                  <td style={{ width: "80%" }}>
                    <b>Benevolent Be-Ro</b>
                    <br />
                    <br />
                    As a result, a free recipe book was produced and handed out
                    at the exhibitions as well as door to door. The&nbsp;
                    <b>Be-Ro</b>&nbsp; cookbook contained recipes to feed hungry
                    families on a very low budget.
                    <br />
                    <br />
                    They soon became an essential part of a young woman's
                    education in running a home and feeding a family.
                    Consequently, the recipe books achieved their objective of
                    making&nbsp;<b>Be-Ro</b>&nbsp;the best known flour in the
                    North.
                  </td>
                  <td style={{ width: "20%" }}>
                    <img
                      alt=""
                      align="right"
                      border="0"
                      height="218"
                      src="/head/1005.gif"
                      width="208"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="recipe" ref={bookRef}>
              <div className="recipe-box">
                <img src="/head/1006.jpg" alt="Our Story" />
                <div className="product-information">
                  <h2>Buy the&nbsp;&nbsp;Be-Ro&nbsp;&nbsp;Recipe Book</h2>
                  <p>
                    First published in 1923, and now in its 41st edition, the
                    "Be-Ro" recipe book is arguably one of the best-selling
                    cookery books ever, with more than thirty eight million
                    copies having been sold.
                    <br />
                    <br />
                    The latest edition is packed with even more inspiring dishes
                    together with a brand new "quick and easy" section which
                    even the novice baker can follow.
                    <br />
                    &nbsp; &nbsp;
                  </p>
                  <div className="btn-buy">
                    <Link
                      to={`/shopDetails/1`}
                      onClick={handleNavigate}
                      className="buy-item"
                    >
                      Buy Now!
                    </Link>
                  </div>
                  <p>
                    For more details, please view our
                    <br />
                    <strong>
                      <Link to="/term" style={{ fontWeight: "bolder" }}>
                        Terms and Conditions
                      </Link>
                    </strong>
                  </p>
                </div>
              </div>
              <div className="recipe-box">
                <div
                  className="product-information"
                  style={{ padding: "10% 2%" }}
                >
                  <h2>Be-Ro Flours</h2>
                  <p>
                    Be-Ro's Flours, available as plain and self-raising, and in
                    a variety of pack sizes, are the ideal flours for all your
                    baking requirements.
                  </p>
                </div>
                <img src="/head/1007.png" alt="Our Story" />
              </div>

              <div className="recipe-box">
                <img src="/head/1008.png" alt="Our Story" />
                <div
                  className="product-information"
                  style={{ padding: "4% 2%" }}
                >
                  <h2>Baking basics... and more!</h2>
                  <p>
                    While you're waiting for your new "Be-Ro 41st Edition"
                    recipe book, or if you just want to test your baking skills,
                    we have put together a small selection of recipes, available
                    with our compliments.
                  </p>
                  <p>You can download them here... </p>
                  <div className="btn-buy" style={{ marginTop: "7%" }}>
                    <Link
                      to="/bero/Be-Ro_website_recipes.pdf"
                      onClick={handleNavigateToPdf}
                      className="buy-item"
                    >
                      Our Recipes
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <h1 ref={faqsRef}>FAQs</h1>
            <div className="faqs">
              <div
                className={`faq-header ${activeIndex === 0 ? "is-active" : ""}`}
                onClick={() => handleToggle(0)}
              >
                Can I order an older edition?
              </div>
              <div
                className="faq-content"
                style={{
                  height: activeIndex === 0 ? "auto" : "0",
                  overflow: "hidden",
                }}
              >
                Only the 41st version is available for sale. We do not have
                stock of older editions.
              </div>
            </div>
            <div className="faqs">
              <div
                className={`faq-header ${activeIndex === 1 ? "is-active" : ""}`}
                onClick={() => handleToggle(1)}
              >
                Do you ship internationally?
              </div>
              <div
                className="faq-content"
                style={{
                  height: activeIndex === 1 ? "auto" : "0",
                  overflow: "hidden",
                }}
              >
                We only fulfil orders to the UK only.
              </div>
            </div>
            <div className="faqs">
              <div
                className={`faq-header ${activeIndex === 2 ? "is-active" : ""}`}
                onClick={() => handleToggle(2)}
              >
                How can I make an order?
              </div>
              <div
                className="faq-content"
                style={{
                  height: activeIndex === 2 ? "auto" : "0",
                  overflow: "hidden",
                }}
              >
                You can order online via our website on&nbsp;
                <Link to="/">be-ro.co.uk</Link>
                <br />
                <strong style={{ color: "#000" }}>
                  We aren't able to take orders over the phone.
                </strong>
              </div>
            </div>
            <div className="faqs">
              <div
                className={`faq-header ${activeIndex === 3 ? "is-active" : ""}`}
                onClick={() => handleToggle(3)}
              >
                How do I know my order has gone through?
              </div>
              <div
                className="faq-content"
                style={{
                  height: activeIndex === 3 ? "auto" : "0",
                  overflow: "hidden",
                }}
              >
                You will receive a confirmation email shortly after you have
                placed your order from&nbsp;
                <Link to="mailto:serve@seekior.xyz">serve@seekior.xyz</Link>
              </div>
            </div>
            <div className="faqs">
              <div
                className={`faq-header ${activeIndex === 4 ? "is-active" : ""}`}
                onClick={() => handleToggle(4)}
              >
                How does the payment show on my bank statement?
              </div>
              <div
                className="faq-content"
                style={{
                  height: activeIndex === 4 ? "auto" : "0",
                  overflow: "hidden",
                }}
              >
                The payment reference will be shown as "&nbsp;
                <b>SP PF Recipe Books"</b>&nbsp;on your Debit/Credit Card
                statement.
              </div>
            </div>

            <div className="faqs">
              <div
                className={`faq-header ${activeIndex === 5 ? "is-active" : ""}`}
                onClick={() => handleToggle(5)}
              >
                How long will it take for my book to arrive?
              </div>
              <div
                className="faq-content"
                style={{
                  height: activeIndex === 5 ? "auto" : "0",
                  overflow: "hidden",
                }}
              >
                Please allow 7 days for the book to be dispatched . Once
                dispatched, express orders will be sent via Royal Mail First
                Class service and standard delivery service will be sent via
                Royal Mail second class service.
              </div>
            </div>
            <div className="faqs">
              <div
                className={`faq-header ${activeIndex === 6 ? "is-active" : ""}`}
                onClick={() => handleToggle(6)}
              >
                What is the cost?
              </div>
              <div
                className="faq-content"
                style={{
                  display: "flex",
                  height: activeIndex === 6 ? "auto" : "0",
                  overflow: "hidden",
                }}
              >
                <div>
                  <table border="1" cellpadding="1" cellspacing="1">
                    <tbody>
                      <tr>
                        <td>Qty Books</td>
                        <td>Cost of book (£)</td>
                        <td>Standard delivery (£)</td>
                        <td>Express delivery (£)</td>
                      </tr>
                      <tr>
                        <td>1</td>
                        <td>5.99 +</td>
                        <td>2.40</td>
                        <td>2.70</td>
                      </tr>
                      <tr>
                        <td>2</td>
                        <td>11.98 +</td>
                        <td>2.70</td>
                        <td>3.30</td>
                      </tr>
                      <tr>
                        <td>
                          3-10
                          <br />
                          (MAX)
                        </td>
                        <td>17.97-- 59.90 +</td>
                        <td>3.49</td>
                        <td>&nbsp;</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div className="faqs">
              <div
                className={`faq-header ${activeIndex === 7 ? "is-active" : ""}`}
                onClick={() => handleToggle(7)}
              >
                Can I order by cheque?
              </div>
              <div
                className="faq-content"
                style={{
                  height: activeIndex === 7 ? "auto" : "0",
                  overflow: "hidden",
                }}
              >
                We have had to make changes to processes and no longer have the
                capability to process cheques. However, the recipe books are
                still available to purchase online direct from our website.
              </div>
            </div>
            <div className="faqs">
              <div
                className={`faq-header ${activeIndex === 8 ? "is-active" : ""}`}
                onClick={() => handleToggle(8)}
              >
                Can I order by cheque?
              </div>
              <div
                className="faq-content"
                style={{
                  height: activeIndex === 8 ? "auto" : "0",
                  overflow: "hidden",
                }}
              >
                We have had to make changes to processes and no longer have the
                capability to process cheques. However, the recipe books are
                still available to purchase online direct from our website.
              </div>
            </div>
            <div className="faqs">
              <div
                className={`faq-header ${activeIndex === 9 ? "is-active" : ""}`}
                onClick={() => handleToggle(9)}
              >
                Why has the cost changed?
              </div>
              <div
                className="faq-content"
                style={{
                  height: activeIndex === 9 ? "auto" : "0",
                  overflow: "hidden",
                }}
              >
                As I am sure you are aware, right now, it's costing
                significantly more to make and distribute products. We have seen
                increases in the cost of producing including paper, printing
                costs, storing and fulfilling the recipe books.
                <br />
                <br />
                We do our best to avoid price increases wherever we can and only
                make changes like this reluctantly. We are proud of all the
                recipes in the latest book and the high quality baking
                inspiration they provide so we hope you will continue to enjoy
                our Be-Ro brand.
              </div>
            </div>

            <div className="contact__box" ref={contactRef}>
              <h1>Contact Us</h1>
              <div className="contact__inner__box">
                <div>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <img
                      alt=""
                      src="/head/bero_logo_whiteoutline.png"
                      width="300px"
                    />
                  </div>
                  <div className="cog-page">
                    <p>
                      To be able to direct your query to the team best placed to
                      assist, we would like to ask you a few questions.
                    </p>
                    <p>
                      Please complete the details below so that we can answer
                      your query quickly and efficiently.
                    </p>
                    <p>Fields marked with an asterisk (*) are mandatory.</p>
                    <label>Why are you contacting us?*</label>
                    <br />
                    <select
                      name="userType"
                      id="userTypeSelect"
                      value={contact}
                      onChange={(e) => setContact(e.target.value)}
                    >
                      <option value="">Please select a reason</option>
                      <option value="consumer">I am a Consumer</option>
                      <option value="supplier">
                        I am a prospective Supplier
                      </option>
                      <option value="investor">I am an Investor</option>
                    </select>
                    <p
                      style={{
                        fontSize: ".85em",
                        width: "350px",
                        marginTop: "-2",
                        lineHeight: "1",
                      }}
                    >
                      This helps us collect the right information and direct
                      your query to the best department to handle it.
                    </p>
                    <label>Where are you?*</label>
                    <br />
                    <select
                      name="userType"
                      id="userTypeSelect"
                      value={site}
                      onChange={(e) => setSite(e.target.value)}
                    >
                      <option value="">Please select a site</option>
                      <option value="UK">UK / Republic of Ireland</option>
                      <option value="Europe">Europe</option>
                      <option value="USA">USA / Canada</option>
                      <option value="Australia">Australia / New Zealand</option>
                      <option value="Other">Other</option>
                    </select>
                    <p>
                      If you have an enquiry or complaint about one of our
                      products or brands, our consumer relations team at The
                      Listening Centre are happy to help.
                    </p>
                    <p>
                      The team is available Monday to Friday 9am-5pm UK, except
                      Bank Holidays. Please provide as much information as
                      possible and retain any packaging or items you have, this
                      will allow us to provide the best help possible.
                    </p>
                    <label>Your reason for contacting us?*</label>
                    <br />
                    <div style={{ display: "flex" }}>
                      <label style={{ display: "flex" }}>
                        <input
                          type="radio"
                          id="enquiry"
                          name="reason"
                          value="enquiry"
                          checked={reason === "enquiry"}
                          onChange={(e) => setReason(e.target.value)}
                          style={{
                            width: " 12.3px",
                            height: "12.3px",
                            marginTop: "15px",
                          }}
                        />
                        <p>An Enquiry</p>
                      </label>
                      <label style={{ display: "flex" }}>
                        <input
                          type="radio"
                          id="complaint"
                          name="reason"
                          value="complaint"
                          checked={reason === "complaint"}
                          onChange={(e) => setReason(e.target.value)}
                          style={{
                            width: " 12.3px",
                            height: "12.3px",
                            marginTop: "15px",
                          }}
                        />
                        <p>A Complaint</p>
                      </label>
                    </div>
                    <label>Is this about a particular Brand?*</label>
                    <br />
                    <select
                      name="userType"
                      id="userTypeSelect"
                      value={brand}
                      onChange={(e) => setBrand(e.target.value)}
                    >
                      <option value="">Please select a brnad</option>
                      <option value="consumer">Ambrosia</option>
                      <option value="supplier">Angel Delight</option>
                      <option value="investor">Atora</option>
                      <option value="investor">Batchelors</option>
                      <option value="investor">Be-Ro</option>
                    </select>
                    <h3>Contact Details</h3>
                    <label>Title</label>
                    <br />
                    <select
                      name="userType"
                      id="userTypeSelect"
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                    >
                      <option value="">Please select a title</option>
                      <option value="consumer">Mr</option>
                      <option value="supplier">Mrs</option>
                      <option value="investor">Ms</option>
                      <option value="investor">Mx</option>
                      <option value="investor">Prefer not to say</option>
                    </select>
                    <br />
                    <label>First Name</label>
                    <br />
                    <input
                      type="text"
                      value={firstname}
                      onChange={(e) => setFirstname(e.target.value)}
                      placeholder="First Name"
                      id="userInput"
                    ></input>
                    <br />
                    <label>Last Name*</label>
                    <br />
                    <input
                      type="text"
                      value={lastname}
                      onChange={(e) => setLastname(e.target.value)}
                      placeholder="Last Name"
                      id="userInput"
                    ></input>
                    <br />
                    <label>Your Address</label>
                    <br />
                    <input
                      type="text"
                      value={add1}
                      onChange={(e) => setAdd1(e.target.value)}
                      placeholder="Address Line1"
                      id="userInput"
                    ></input>
                    <br />
                    <input
                      type="text"
                      value={add2}
                      onChange={(e) => setAdd2(e.target.value)}
                      placeholder="Address Line2"
                      id="userInput"
                    ></input>
                    <br />
                    <div style={{ display: "flex" }}>
                      <input
                        type="text"
                        value={city}
                        onChange={(e) => setCity(e.target.value)}
                        placeholder="City"
                        style={{
                          width: "161px",
                          height: "35px",
                          marginRight: "2%",
                          backgroundColor: "hsl(0deg 0% 80% / 30%)",
                          border: "none",
                        }}
                      ></input>
                      <input
                        type="text"
                        value={state}
                        onChange={(e) => setState(e.target.value)}
                        placeholder="State/Province/Region"
                        style={{
                          width: "161px",
                          height: "35px",
                          backgroundColor: "hsl(0deg 0% 80% / 30%)",
                          border: "none",
                        }}
                      ></input>
                    </div>
                    <label>Post Code*</label>
                    <br />
                    <input
                      type="text"
                      value={code}
                      onChange={(e) => setCode(e.target.value)}
                      placeholder="PostCode/Zip-Code"
                      id="userInput"
                    ></input>
                    <br />
                    <label>Country*</label>
                    <br />
                    <input
                      type="text"
                      value={country}
                      onChange={(e) => setCountry(e.target.value)}
                      placeholder="Select a Country,or Type an entry"
                      id="userInput"
                    ></input>
                    <br />
                    <br />
                    <br />
                    <br />
                    <label>Phone</label>
                    <br />
                    <input
                      type="text"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                      id="userInput"
                    ></input>
                    <p
                      style={{
                        fontSize: ".85em",
                        width: "350px",
                        marginTop: "-2",
                        lineHeight: "1",
                      }}
                    >
                      Please include any international prefixes if you are
                      outside the UK
                    </p>
                    <br />
                    <label>Email*</label>
                    <br />
                    <input
                      type="text"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      id="userInput"
                    ></input>
                    <br />
                    <label>Confirm Email Address*</label>
                    <br />
                    <input
                      type="text"
                      value={emailAdd}
                      onChange={(e) => setEmailadd(e.target.value)}
                      id="userInput"
                    ></input>
                    <p
                      style={{
                        fontSize: ".85em",
                        width: "350px",
                        marginTop: "-2",
                        lineHeight: "1",
                      }}
                    >
                      Please enter your email address again to confirm it is
                      correct
                    </p>
                    <br />
                    <br />
                    <br />
                    <br />

                    <br />
                    <h3>How can we help you?</h3>
                    <label>Your Message*</label>
                    <br />
                    <textarea
                      value={msg}
                      onChange={(e) => setMsg(e.target.value)}
                      style={{ height: "57px" }}
                    ></textarea>
                    <br />
                    <br />
                    <br />
                    <h3>Supporting documents, photo, etc</h3>
                    <p>
                      If you have any supporting documents (eg: photos) please
                      upload them below here. If uploading multiple documents,
                      please ensure that all file names are different.
                    </p>
                    <p>
                      If you have a foreign body, please could you keep hold of
                      it until our team contacts you back as we may send you a
                      pre-paid retrieval pack as part of our investigation
                      process.
                    </p>
                    <label>File Upload</label>
                    <br />
                    <form onSubmit={handleFileSubmit}>
                      <input
                        type="file"
                        id="fileUpload"
                        name="fileUpload"
                        // accept="application/pdf"
                        onChange={handleFileChange}
                      />
                    </form>
                    <p
                      style={{
                        fontSize: ".85em",
                        marginTop: "-2",
                        lineHeight: "1",
                      }}
                    >
                      You may upload a maximum of 5 files, each being a maximum
                      of 10Mb. Allowed types are: JPG, PNG, GIF, PDF, DOC, XLS.
                      Please ensure that all file names are different, e.g.
                      image1.jpg, image2.jpg
                    </p>
                    <br />
                    <br />
                    <h3>Privacy Policy</h3>
                    <p>
                      For the purpose of recording and addressing the feedback
                      that you provide in this information form, we will be
                      required to collect and use your 'Personal Data'. Personal
                      Data includes your name, contact details and the nature of
                      your contact.
                    </p>
                    <p>
                      The data will be collected, stored, collated and handled
                      by Premier Foods Group Limited and its third party service
                      provider for the purpose of responding to and addressing
                      your consumer feedback.
                    </p>
                    <p>
                      If your query relates to Soba or Cup Noodle products, we
                      will be sending your data to the Nissin Group who will
                      manage and respond directly to your contact.
                    </p>
                    <p>
                      If you have any further queries, or would like more
                      information about how your Personal Data will be used,
                      please see the corresponding privacy policy:
                    </p>
                    <Link to="https://www.premierfoods.co.uk/privacy">
                      UK Websites Privacy Policy
                    </Link>
                    <br />
                    <Link to="https://www.sharwoods.eu/English%20-%20United%20Kingdom/Other-Pages-1/GDPR-Privacy-Policy">
                      Sharwood's EU Privacy Policy
                    </Link>
                    <br />
                    <Link to="https://bisto.ie/images/GDPR_PrivacyPolicy.pdf">
                      Bisto Ireland Privacy Policy
                    </Link>
                    <br />
                    <Link to="https://plantasticeu.pfstaging.net/PlantasticEU/media/Documents/English%20policies/Privacy-Policy-EN-Plantastic-EU.pdf">
                      Plantastic EU Privacy Policy
                    </Link>
                    <p>
                      By submitting this form you confirm that you agree with
                      these terms.
                    </p>
                    <br />
                    <button value="Submit" onClick={handlePlaceOrder}>
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <ul className="foot__ul__li">
              <li>
                <Link to="/">0800 032 7111</Link>
              </li>
              <li>
                <Link to="/" onClick={handleNavigateToContact}>
                  Contact Form
                </Link>
              </li>
              <li>
                <Link to="https://www.premierfoods.co.uk/">
                  premierfoods.co.uk
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Main;
