import React from "react";
import "../css/footer.css";
import { Link } from "react-router-dom";

function Footer() {
  const toTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleNavigateToPrivacyPdf = (e) => {
    e.preventDefault();
    window.open("/bero/Premier-Foods-Privacy-Statement.pdf", "_blank");
  };
  const handleNavigateToTermPdf = (e) => {
    e.preventDefault();
    window.open("/bero/Premierfoods-Terms-of-Site-Use.pdf", "_blank");
  };
  return (
    <>
      <div className="bottom-container">
        <div className="bot__Top">
          <div className="bot__Top__inner">
            <img alt="Premier Foods Logo" src="/head/foot4.png" />
          </div>
        </div>
        <div className="botLeft">
          <div className="comImfo">
            <ul>
              <li>
                <Link to="/" onClick={toTop}>
                  Home
                </Link>
              </li>
              <li>
                <Link
                  to="/bero/Premier-Foods-Privacy-Statement.pdf"
                  onClick={handleNavigateToPrivacyPdf}
                >
                  Privacy
                </Link>
              </li>
              <li>
                <Link
                  to="/bero/Premierfoods-Terms-of-Site-Use.pdf"
                  onClick={handleNavigateToTermPdf}
                >
                  Terms of Site Use
                </Link>
              </li>
            </ul>
          </div>
          <p>© Copyright Premier Foods 2024</p>
        </div>
      </div>
    </>
  );
}

export default Footer;
