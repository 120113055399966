import React, { useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import "../css/bottom.css";
import { Link } from "react-router-dom";

function Contact() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");

  const handlePlaceOrder = () => {
    const newErrors = {};

    if (!name) newErrors.name = "Name is required";
    if (!email) newErrors.email = "Email is required";

    // setErrors(newErrors);

    if (Object.keys(newErrors).length > 0) {
      alert("Please fill name and email");
    } else {
      alert("send Success!");
    }
  };
  return (
    <div className="main-container">
      <Header />
      <div className="term-h2">
        <h1>Contact us & FAQs.</h1>
        <p className="title__p">We’re here to help.</p>
        <div className="side-panel">
          <div className="side-panel-inner">
            <img alt="" src="/head/fj.png" />
            <p className="gray-p">Client Success Team</p>
            <h1>Schedule a call</h1>
            <p className="gray-p">
              <img
                src="https://img.icons8.com/material-sharp/50/737373/clock.png"
                alt="clock"
              />
              30 min
            </p>
            <p>
              If you’d like to talk to us about our products or services, please
              feel free to schedule a video call with one of our awesome FATJOE
              team members.
            </p>
          </div>
        </div>
      </div>
      <div className="bzQKdY">
        <div className="bzQKdY-inner">
          <div className="bzq-grid">
            <div className="sc">
              <h3>By telephone</h3>
              <ul>
                <li>🇬🇧 (01543) 648033</li>
                <li>🇺🇸 (949) 239 6225</li>
                <li>🇦🇺 +61 2 8311 1549</li>
              </ul>
            </div>
            <div className="sc">
              <h3>UK Head Office</h3>
              <ul>
                <li>Point North, Park Plaza,</li>
                <li>Staffordshire, WS12 2DB,</li>
                <li>United Kingdom</li>
                <li>Email: serve@seekior.xyz</li>
              </ul>
            </div>
            <div className="sc">
              <h3>US Postal</h3>
              <ul>
                <li>1968 S. Coast Hwy #403</li>
                <li>Laguna Beach CA 92651</li>
                <li>United States</li>
              </ul>
            </div>
            <div className="sc">
              <h3>Company Information</h3>
              <ul>
                <li>FATJOE (Get Ideal Limited)</li>
                <li>Company No: 08475801</li>
                <li>VAT No: 171579093</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="contactAll">
        <div className="contactLeftBottomTitle">
          <h3 className="question-h3">
            Questions? Check out our FAQs.
            <img alt="" src="/head/arrow.png" style={{ marginBottom: "-3%" }} />
          </h3>
          <div className="jpBCOT">
            <div className="jpBCOT-inner">
              <div className="eVsLku">
                <h3>What type of clients order from FATJOE?</h3>
              </div>
              <div className="ksAkgF">
                <p>
                  FATJOE is designed for SEO Agencies, SEO Resellers, Marketing
                  Teams and Publishers however, anyone who has an understanding
                  of SEO can use our services.
                </p>
              </div>
            </div>
            <div className="jpBCOT-inner">
              <div className="eVsLku">
                <h3>Why should I use FATJOE?</h3>
              </div>
              <div className="ksAkgF">
                <p>
                  There are many compelling reasons to use FATJOE. Check out 9
                  reasons why over 5000 SEO Agencies use us everyday.
                </p>
              </div>
            </div>
            <div className="jpBCOT-inner">
              <div className="eVsLku">
                <h3>Where can I see real reviews of FATJOE?</h3>
              </div>
              <div className="ksAkgF">
                <p>We have hundreds of real verified customer reviews here.</p>
              </div>
            </div>
            <div className="jpBCOT-inner">
              <div className="eVsLku">
                <h3>Do you offer bulk discounts?</h3>
              </div>
              <div className="ksAkgF">
                <p>
                  We offer a monthly credit subscription that saves you 10% on
                  all orders.
                </p>
                <p>
                  It’s called Subscribe & Save. All you need to do is subscribe
                  to at least £2,500 of FATJOE credit per month and you’ll
                  unlock an account-level 10% discount on all services.
                </p>
                <p>
                  With a monthly subscription of £2,500 you can save over £3,000
                  over the year.
                </p>
                <p>
                  To subscribe you simply need to hit ‘Credit’ in your account,
                  and then set your subscription level.
                </p>
              </div>
            </div>

            <div className="jpBCOT-inner">
              <div className="eVsLku">
                <h3>Can we set up a call / meeting?</h3>
              </div>
              <div className="ksAkgF">
                <p>
                  Absolutely! You can schedule a call with our Support team
                  using the calendar above. We don’t do sales or consultancy,
                  but we’re more than happy to answer any questions you have!
                  Alternatively for a more thorough in-depth response drop us an
                  email to serve@seekior.xyz and you’ll be handled by the best
                  support team member for your enquiry.
                </p>
              </div>
            </div>

            <div className="jpBCOT-inner">
              <div className="eVsLku">
                <h3>
                  Can you help with questions I have regarding a particular
                  service?
                </h3>
              </div>
              <div className="ksAkgF">
                <p>
                  We cover all service-specific FAQs on each service page. Just
                  navigate to Link Building, Content Writing, SEO or Design &
                  Video and select the service you want to view FAQs for.
                </p>
              </div>
            </div>

            <div className="jpBCOT-inner">
              <div className="eVsLku">
                <h3>How do I place an order with FATJOE?</h3>
              </div>
              <div className="ksAkgF">
                <p>
                  Create a free dashboard account here. It takes about 10
                  seconds to set up your dashboard, and once you’re inside, you
                  can place, manage, and track orders to your heart’s content!
                </p>
              </div>
            </div>

            <div className="jpBCOT-inner">
              <div className="eVsLku">
                <h3>Does FATJOE work with agencies in multiple countries?</h3>
              </div>
              <div className="ksAkgF">
                <p>
                  All of our content services are written and designed in the
                  English language. For Local Citations we cover submissions for
                  the UK, US, Australia and Canada. For Blogger Outreach we
                  secure placements on UK, US, Australian and Canadian blogs
                  however we can’t currently guarantee all placements for
                  Australian or Canadian blogs just yet. For our Press Release
                  Distributions we currently distribute via mainly a US network
                  although some UK press sites are included within the same
                  release.
                </p>
              </div>
            </div>

            <div className="jpBCOT-inner">
              <div className="eVsLku">
                <h3>
                  Are there any contracts or minimum orders to use your
                  services?
                </h3>
              </div>
              <div className="ksAkgF">
                <p>
                  No need for any contracts when using our services. Just create
                  a free dashboard account and order any services you like as
                  and when you need them in absolutely any quantity you need.
                </p>
              </div>
            </div>
            <div className="jpBCOT-inner">
              <div className="eVsLku">
                <h3>How does Subscribe & Save work?</h3>
              </div>
              <div className="ksAkgF">
                <p>
                  Subscribe & Save is our monthly credit subscription designed
                  to help you save while locking in reliable and regular
                  transactions that your accounts teams will love.
                </p>
                <p>
                  Simply subscribe to at least £2,500 of FATJOE credit per
                  month, and you’ll unlock a 10% discount on all orders across
                  all services – it’s that simple.
                </p>
                <p>
                  The credit is ready to be used in your account right away – no
                  more messing about with purchase orders and accounts forms
                  every time you want to purchase one of our productized
                  services.
                </p>
                <p>
                  Of course, if you ever need more credit in a month, you can
                  add credit top-ups easily.
                </p>
                <p>
                  To cancel a Subscribe & Save subscription, you just need to
                  head to Subscriptions in your client dashboard. Your
                  subscription will be ended immediately and you will lose the
                  10% discount, but you will of course keep any credits you have
                  purchased.
                </p>
              </div>
            </div>
          </div>
          <h3 className="question-h3">
            Still need help? Submit your enquiry below.
          </h3>
          <h3 className="question-h3">
            <img alt="" src="/head/arrow2.png" />
          </h3>

          <form className="sc-b1db88f2-0 brWAV sc-b7d9ff88-2 bKHWDb">
            <div className="sc-b7d9ff88-3 bRSkcr">
              <label for="name">Your Name</label>
              <input
                id="name"
                mode="light"
                type="text"
                className="sc-53a12312-0 iwuviq error"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="sc-b7d9ff88-3 bRSkcr">
              <label for="email">Email address</label>
              <input
                id="email"
                mode="light"
                type="email"
                placeholder=""
                autocomplete="email"
                className="sc-53a12312-0 iwuviq Please enter your email address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="sc-b7d9ff88-3 bRSkcr">
              <label for="message">Message</label>
              <div className="sc-ec4ecc1f-0 heogD">
                <textarea
                  id="message"
                  placeholder="Write your message here."
                  className="sc-ec4ecc1f-1 kZQjNV Please enter some details"
                  maxlength="1000"
                ></textarea>
              </div>
            </div>
            <button
              className="sc-38968dfa-0 frmCwJ sc-b7d9ff88-5 ifaISG"
              type="submit"
              display="block"
              value="Submit"
              onClick={handlePlaceOrder}
            >
              Send Message
            </button>
          </form>

          <div className="cZVIut">
            <div className="cZVIut-inner">
              <div className="cZVIut-left">
                <h2>The no hassle way to get SEO done.</h2>
                <div className="itghAB">
                  <div className="itghAB-inner">
                    <p className="sc-ccaf7f5a-0 jhXoIX sc-d0e3d4ce-3 ZFJiO">
                      2012
                    </p>
                    <p className="sc-ccaf7f5a-0 hAraIS sc-d0e3d4ce-4 kQfVju">
                      Founded In
                    </p>
                  </div>
                  <div className="itghAB-inner">
                    <p className="sc-ccaf7f5a-0 jhXoIX sc-d0e3d4ce-3 ZFJiO">
                      40k+
                    </p>
                    <p className="sc-ccaf7f5a-0 hAraIS sc-d0e3d4ce-4 kQfVju">
                      Agency Accounts
                    </p>
                  </div>
                  <div className="itghAB-inner">
                    <p className="sc-ccaf7f5a-0 jhXoIX sc-d0e3d4ce-3 ZFJiO">
                      20+
                    </p>
                    <p className="sc-ccaf7f5a-0 hAraIS sc-d0e3d4ce-4 kQfVju">
                      Services Offered
                    </p>
                  </div>
                </div>
                <Link to="/">
                  <button>Explore Our Services</button>
                </Link>
              </div>
              <div className="cZVIut-right">
                <img alt="" src="/head/cry.png" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Contact;
